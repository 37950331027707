<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-dialog :fullscreen="fullScreen" v-model="cartDialog" v-if="cart">
      <v-card elevation="0">
        <v-toolbar
          dark
          color="primary"
          flat
          style="padding-right: 10px !important"
        >
          <v-toolbar-title class="overline"
            >Pedido {{ cart.id.split("-")[0].toUpperCase() }}</v-toolbar-title
          >
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="cartDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <div id="content">
            <v-container>
              <div style="border: 1px grey solid; mardin: 10px; padding: 15px">
                <v-row class="d-flex justify-center">
                  <v-card-title class="overline">Dados do Pedido</v-card-title>
                  <v-row>
                    <v-col cols="6">
                      <v-card-text>
                        <h6>Cliente:</h6>
                        {{ cart.customerName }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6">
                      <v-card-text>
                        <h6>Fone:</h6>
                        {{ cart.customerPhone }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Data Abertura:</h6>
                        {{ formatDate(cart.createdAt) }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Data Fatura:</h6>
                        {{ formatDate(cart.closedDate) }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Prazo Entrega:</h6>
                        {{ formatDate(cart.deadlineTime) }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Forma de Pagamento:</h6>
                        {{
                          cart && cart.paymentMethod
                            ? cart.paymentMethod.name
                            : "Indefinido"
                        }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="12" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Endereço Entrega:</h6>
                        {{ setAddress() }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-row>

                <v-divider />

                <v-data-table
                  dense
                  :loading="loading"
                  :headers="detailHeaders"
                  :items="cart.items"
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.totalPrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.totalPrice) }}</span>
                  </template>
                </v-data-table>

                <br />
                <v-row>
                  <v-col cols="4">
                    <v-card elevation="0">
                      <v-card-subtitle>Total Itens</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$ {{ formatPrice(cart.amountItems) }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0">
                      <v-card-subtitle>Taxa Entrega</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$
                        {{
                          formatPrice(
                            cart.deliveryRate ? cart.deliveryRate : 0.0
                          )
                        }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0">
                      <v-card-subtitle>Total do Pedido</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$ {{ formatPrice(cart.amountTotal) }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </v-card>
      </v-card>
      <v-btn @click="downloadWithCSS">Exportar PDF</v-btn>
      <v-btn @click="printScreen">Imprimir</v-btn>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="carts"
          :search="search"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span> {{ item.id.split("-")[0].toUpperCase() }}</span>
          </template>
          <template v-slot:[`item.amountTotal`]="{ item }">
            <span>R$ {{ formatPrice(item.amountTotal) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="getCart(item)"> <v-icon>mdi-eye</v-icon></v-btn>
          </template>

          <template v-slot:[`item.closedDate`]="{ item }">
            <span>{{ formatDate(item.closedDate) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default Vue.extend({
  name: "AllSales",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Pedidos",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Pedidos em Andamento",
            to: "/pedbem/sales-in-progress",
            permission: "gerentor.sales.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    exportMode: false,
    carts: [],
    cart: "",
    cartDialog: false,
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "id",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod.name",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountTotal",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    detailHeaders: [
      {
        text: "Quantidade",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Produto",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "Comentários",
        align: "start",
        value: "notes",
        filtering: true,
      },
      {
        text: "Total Item R$",
        value: "totalPrice",
        filtering: true,
      },
    ],
  }),
  methods: {
    printScreen() {
      const prtHtml = document.getElementById("content").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    downloadWithCSS() {
      html2canvas(document.getElementById("content")).then(function (canvas) {
        var wid;
        var hgt;
        var img = canvas.toDataURL(
          "image/png",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        var hratio = hgt / wid;
        var doc = new jsPDF("p", "pt", "a4");
        var width = doc.internal.pageSize.width;
        var height = width * hratio;
        doc.addImage(img, "JPEG", 10, 20, width - 20, height);
        doc.save("Test.pdf");
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    setAddress() {
      const cart = this.cart;
      if (cart && cart.street)
        return `${cart?.street}, ${cart?.number} - ${cart?.zone} - ${cart?.city}/${cart?.state}`;
      return "Endereço não definido";
    },

    getCart(item) {
      this.cart = item;
      this.cartDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("delivery/sales").then(
        (data) => {
          this.carts = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
  },
  directives: { money: VMoney },
  mounted() {
    this.getItems();
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
